
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
body {
  font-family: 'Inter', sans-serif !important;
  background-color: white !important;
  }
  
  .color-grey {
    color: lightgrey!important;
  }
  
  .creation_date {
    color: grey;
    font-size: 12px;
  }
  .virtual {
    font-size: 12px;
  }
  
  .left-sidebar {
    
    background: #0b0423!important;
    padding-top: 130px!important;
    }
  
   #sidebarnav {
    background: #0b0423!important;
  
   }
    .logo-text img {
      width: 200px;
    }
  
    .topbar .top-navbar .navbar-header {
      background: #0b0423!important;
    }
  
    .sidebar-nav ul .sidebar-item.selected>.sidebar-link, .sidebar-nav ul .sidebar-item .sidebar-link {
      color: #c4cbd2!important;
      margin: 0px 11px;
      font-family: 'Inter', sans-serif !important;
      letter-spacing: 1px;
    }
  
    .sidebar-nav ul .sidebar-item .first-level {
      background: #231f3a!important;
      margin: 0px 15px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  
    .sidebar-nav ul .sidebar-item .sidebar-link.active {
      border:none;
      background: #231f3a!important;
      margin: 0px 15px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  
    span.undermenu:hover {
      color: orange!important;
    }
  
    .sidebar-nav ul .sidebar-item .first-level .sidebar-item .sidebar-link {
      padding: 10px 1px;
    }
  
    .sidebar-nav ul .sideba .sidebar-link:hover {
      background: none!important;
    }
  
  
  .sidebar-nav ul .sidebar-item .sidebar-link i {
    color: #a7a7a7!important;
  }
  .topbar .navbar-collapse {
    background: white!important;
  }
  
  
  .topbar .navbar-collapse .navbar-nav .app-search .form-control {
    background: aliceblue;
  }
  
  .card {
    box-shadow: 0px 10px 10px 20px rgba(176, 184, 214 , 9%), 10px 10px 15px -5px #afbef7;
    border-radius: 8px;
    background: white;
  }
  
  .font-700 {
    font-weight: 700!important;
  }
  
  .white-box {
    border-radius: 8px;
    padding: 20px!important;
    margin-bottom: 0px!important;
  }
  
  .white-box .box-title {
    line-height: normal;
  }
  .box-title {
    margin: 0px;
  }
  .f13-px {
    font-size: 13px;
  
  }
  
  .color-blue {
    color: blue!important;
  }
  
  .bord:after {
    content: "";
      position: absolute;
      width: 90px;
      height: 1px;
      background: #eaeaea;
      transform: rotate(
  90deg
  );
      left: 30%;
      top: 60px;
  
  }
  
  .bord2:after {
    content: "";
      position: absolute;
      width: 72px;
      height: 1px;
      background: #eaeaea;
      transform: rotate(
  90deg
  );
      left: 42%;
      top: 36%;
  
  }
  
  .colorchange {
    background: #0b0423;
  }
  
  .colorchange tr th {
    color: white!important;
      font-weight: 100;
      font-size: 11px;
      text-transform: uppercase;
      letter-spacing: 1px;
      text-align: center;
  }
  
  .center tr td {
    text-align: center;
  }
  
  .font-16 {
    font-size: 16px!important;
  }
  
  .text-cy {
    color: #424e79!important;
  }
  
  .sidebar-nav ul .sidebar-item .first-level .sidebar-item.active .sidebar-link {
    background: none!important;
    color: orange!important;
  }
  .labl {
    font-size: 11px;
      color: darkgrey;
  }
  
  .customform {
    background: #f4f5f7;
    border: 1px solid grey;
      border-radius: 15px;
      height: 47px!important;
  }
  .cancel {
    border-radius: 12px;
    background: lightgrey;
  
    padding: 6px 90px;
    transition:0.5s;
  }
  
  
  .change {
    border-radius: 12px;
    background: #0b0423;
    color: white;
    padding: 6px 50px
  }
  .change:hover {
    color: white;
  }
  .form-floating>.form-control:focus~label, .form-floating>.form-control:not(:placeholder-shown)~label, .form-floating>.form-select~label {
    transform: scale(0.85) translateY(-1rem) translateX(1.3rem)
  }
  
  .form-floating>.form-control, .form-floating>.form-select {
    padding: 1rem 1.75rem;
  }
  
  .radi {
   border-radius : 15px;
  }
  th:first-child {
    border-bottom-left-radius: 17px;
      border-top-left-radius: 17px;
  }
  
  th:last-child {
    border-bottom-right-radius: 17px;
      border-top-right-radius: 17px;
  }
  
  
  
  .PrivateTabIndicator-colorSecondary-3 {
    background-color: blue!important;
  }
  
  .clear {
    background: none;
    color: orange;
  }
  .bg-lights {
    background: #f6f6f6cc!important;
  }
  
  .darkgrey {
    color: darkgray;
  }
  
  .load {
    background: #0b0423!important;
    
  }
  
  a:hover {
    text-decoration: none!important;
  }
  
  .bg-main {
    background: #0b0423!important;
  color: white!important;
  }
  
  .bicon {
    background: #ff7f00;
      color: white;
      border-radius: 100px;
      padding: 2px;
  }
  
  .exchange {
    background: blue;
    color: white;
    border-radius: 100px;
    padding: 2px;
  }
  
  h3 {
    line-height: normal!important;
  }
  
  .w-35 {
    width: 35px;
  }
  
  .rs-sidenav .rs-nav-item .rs-nav-item-content, .rs-sidenav .rs-dropdown .rs-dropdown-toggle {
    padding-left: 30px!important;
  }
  
  .rs-sidenav-collapse-in .rs-dropdown > .rs-dropdown-menu {
        background: #231f3a!important;
        color: white!important;
  }
  
  .rs-nav-default .rs-nav-item:not(.rs-nav-item-active):not(.rs-nav-item-disabled) > .rs-nav-item-content:active {
    background: #231f3a!important;
    color: white!important;
  
  }
  
  .rs-btn-subtle:active:hover, .rs-btn-subtle.rs-btn-active:hover, .rs-open > .rs-dropdown-toggle.rs-btn-subtle:hover, .rs-btn-subtle:active:focus, .rs-btn-subtle.rs-btn-active:focus, .rs-open > .rs-dropdown-toggle.rs-btn-subtle:focus, .rs-btn-subtle:active.focus, .rs-btn-subtle.rs-btn-active.focus, .rs-open > .rs-dropdown-toggle.rs-btn-subtle.focus {
    background: #231f3a!important;
    color: white!important;
  
  }
  
  .rs-btn-subtle:active:hover, .rs-btn-subtle.rs-btn-active:hover, .rs-open > .rs-dropdown-toggle.rs-btn-subtle:hover, .rs-btn-subtle:active:focus, .rs-btn-subtle.rs-btn-active:focus, .rs-open > .rs-dropdown-toggle.rs-btn-subtle:focus, .rs-btn-subtle:active.focus, .rs-btn-subtle.rs-btn-active.focus, .rs-open > .rs-dropdown-toggle.rs-btn-subtle.focus {
    background: #231f3a!important;
    color: white!important;
  }
  
  .rs-sidenav-collapse-in .rs-dropdown > .rs-dropdown-menu {
    border-radius: 8px!important;
    margin: 10px;
    margin-top: 0px;
  }
  
  li.rs-nav-item:hover:focus {
    background: #231f3a!important;
  }